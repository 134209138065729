<template>
    <div class="pobo-support-form"
         :class="{'pobo-support-form--hidden': isTransitioning, 'pobo-support-form__loading' : loading, 'pobo-support-form__iframe': isIframe, 'padding-disabled': orderDesign}">
        <template v-if="!orderDesign">
            <div class="pobo-support-form__head" v-if="!isIframe">
                <img src="@public/images/logo.png"
                     class="pobo-support-form__head-logo"
                     alt="Pobo Page Builder Support"
                >
                <div class="pobo-support-form__circle-wrap">
                    <div
                        :class="['pobo-support-form__circle', { 'pobo-support-form__circle--active': isOnline, 'pobo-support-form__circle--offline': !isOnline }]"></div>
                    <p v-if="isOnline"> {{ $t('We are online') }}</p>
                    <p v-else> {{ $t('We are offline') }} </p>
                    <span class="version-text"> v. {{ versionApp }} </span>
                </div>
            </div>
            <div class="pobo-support-form__app">
                <information-box :heading="infoTitle" :text="infoText"/>
                <div class="pobo-support-form__app-components">
                    <choose-problem
                        @dataConfirm="changeDataConfirmState(0, $event)"
                        @selected="handleProblemSelection"
                        v-show="currentStep === 0"
                    />
                    <email-template
                        @dataConfirm="changeDataConfirmState(1, $event)"
                        @updateEmail="handleDataUpdate('email', $event)"
                        v-show="currentStep === 1"
                    />
                    <message-template
                        @filesUploaded="handleDataUpdate('files', $event)"
                        @dataConfirm="changeDataConfirmState(2, $event)"
                        @updateMessage="handleDataUpdate('message', $event)"
                        v-show="currentStep === 2"
                    />
                    <url-template
                        @dataConfirm="changeDataConfirmState(3, $event)"
                        @updateUrls="handleDataUpdate('urls', $event)"
                        v-show="currentStep === 3"
                    />
                    <credit-order
                        @dataConfirm="changeDataConfirmState(4, $event)"
                        @updateCredit="handleDataUpdate('credits', $event)"
                        v-show="currentStep === 4"
                    />

                    <div class="pobo-support-form__all-info" v-if="currentStep === 5">
                        <ul>
                            <li>{{ $t('Request:') }} {{ $t(formData.problem) }}</li>
                            <li>{{ $t('Contact email:') }} {{ formData.email }}</li>
                            <li v-if="selectedProblem !== 4">{{ $t('Message:') }} {{ formData.message }}</li>
                            <li v-if="selectedProblem === 1 || selectedProblem === 2">{{ $t('Product URLs:') }}
                                <span v-for="(item, index) in formData.urls" :key="index">
                                        {{ index + 1 }}.{{ item }} &nbsp;
                                </span>
                            </li>
                            <li v-if="selectedProblem === 4"> {{ $t('Number of credits to order:') }} {{
                                    formData.credits
                                }}
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div class="pobo-support-form__btn-wrap">
                <button
                    v-if="currentStep > 0"
                    @click="makeStep(false)"
                    class="pobo-support-form__btn-gray">
                    {{ $t('Go back one step') }}
                </button>
                <button
                    v-if="currentStep < 5"
                    :disabled="!isNextStepPossible"
                    @click="makeStep(true)"
                    class="pobo-support-form__btn">
                    {{ $t('Proceed to the next step') }}
                </button>
                <button
                    v-if="currentStep === 5"
                    @click="sendEmail(true)"
                    class="pobo-support-form__btn">
                    <img src="@public/images/icons/send-ico.png" alt="send-form">
                    {{ $t('Submit form') }}
                </button>
            </div>
        </template>
        <template v-else>
            <div class="design-selector-wrap">
                <design-selector @updateMessage="handleDataUpdate('message', $event)"/>

                <button
                    @click="sendDataForCustomDesign"
                    class="pobo-support-form__btn">
                    <img src="@public/images/icons/send-ico.png" alt="send-form">
                    {{ $t('Send inquiry') }}
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import ChooseProblem from "@/js/supportComponents/ChooseProblem.vue"
import InformationBox from "@/js/supportComponents/InformationBox.vue";
import EmailTemplate from "@/js/supportComponents/EmailTemplate.vue";
import MessageTemplate from "@/js/supportComponents/MessageTemplate.vue";
import UrlTemplate from "@/js/supportComponents/UrlTemplate.vue";
import CreditOrder from "@/js/supportComponents/CreditOrder.vue";
import DesignSelector from "@/js/customDesign/DesignSelector.vue";
import axios from "axios";
import SuccessPage from "@/js/pages/SuccessPage.vue";
import moment from "moment-timezone";
import UAParser from 'ua-parser-js';
import packageJson from "../../../package.json";

export default {
    name: 'SupportForm',

    components: {
        "information-box": InformationBox,
        "choose-problem": ChooseProblem,
        "email-template": EmailTemplate,
        "message-template": MessageTemplate,
        "url-template": UrlTemplate,
        "credit-order": CreditOrder,
        "design-selector": DesignSelector,
    },

    computed: {
        isOnline() {
            const now = moment().tz('Europe/Prague');
            const hour = now.hour();
            const dayOfWeek = now.day();
            return (hour >= 9 && hour < 17) && (dayOfWeek >= 1 && dayOfWeek <= 5);
        },

        infoTitle() {
            const {infoBox, currentStep} = this;
            if (infoBox[currentStep]) {
                return infoBox[currentStep].title
            } else {
                return ''
            }
        },

        infoText() {
            const {infoBox, currentStep} = this;
            if (infoBox[currentStep]) {
                return infoBox[currentStep].text
            } else {
                return ''
            }
        },

        isNextStepPossible() {
            const {confirmData, currentStep} = this;

            return confirmData[currentStep];
        },
    },

    mounted() {
        this.eshopData = this.getURLParams();

        const urlParams = new URLSearchParams(window.location.search);
        this.isIframe = urlParams.get('isIframe') === 'true';
        this.orderDesign = urlParams.get('orderDesign') === 'true';
        if (this.orderDesign) {
            this.formData.message = 'Nová objednávka balíčku: ' + $t('Customizing the look');
        }

        const parser = new UAParser();
        const result = parser.getResult();

        this.formData.browser = result.browser.name + ' ' + result.browser.version;
        this.formData.os = result.os.name + ' ' + result.os.version;
        this.formData.device = result.device.model ? result.device.vendor + ' ' + result.device.model : 'Desktop';

        this.startTime = Date.now();
    },

    data() {
        return {
            loading: false,
            currentStep: 0,
            maxStep: 5,
            selectedProblem: 0,
            isTransitioning: false,
            confirmData: [false, false, false, false, false],
            eshopData: {},
            isIframe: false,
            orderDesign: false,
            startTime: null,
            versionApp: packageJson.version,
            formData: {
                problem: null,
                email: null,
                message: null,
                urls: [],
                credits: 0,
                files: [],
                browser: null,
                os: null,
                device: null,
                time: null,
            },
            infoBox: [
                {
                    title: 'What problem are you experiencing?',
                    text: 'Select the category that best matches your issue.',
                },
                {
                    title: 'Which email can we contact you on?',
                    text: 'We will contact you after resolving your request or for additional information.',
                },
                {
                    title: 'Please describe your request as much as possible',
                    text: 'To assist you, we need as much information as possible.',
                },
                {
                    title: 'Insert the specific URL where the issue occurs',
                    text: 'Please insert as many specific error URLs as possible.',
                },
                {
                    title: 'How many credits would you like to order?',
                    text: 'The minimum order is 500 credits. 1 credit = 1 CZK.',
                },
                {
                    title: 'Summary of information',
                    text: 'Is everything correct? If so, you may submit the request.',
                },
            ],
            stepsMap: {
                0: [0, 1, 2, 5],
                1: [0, 1, 3, 2, 5],
                2: [0, 1, 3, 2, 5],
                3: [0, 1, 2, 5],
                4: [0, 4, 1, 5],
                5: [0, 1, 2, 5],
                6: [0, 1, 2, 5],
            },
            activeSteps: [],
        }
    },

    methods: {
        /*
         sendSlack(data) {
             axios.post('/api/send-slack-message', data)
                 .then(response => {
                     this.uploadedFiles = [];
                 })
                 .catch(error => console.error('Send slack message error', error));
         },
         */

        changeDataConfirmState(value, data) {
            this.confirmData[value] = data;
        },

        sendDatToGoogleSheets(id, created_at) {
            const data = {
                request_id: id ? id : 'neuvedeno',
                created_at: created_at ? created_at : 'neuvedeno',
                email: this.formData.email ? this.formData.email : 'neuvdedeno',
                eshop: this.eshopData.eshop ? this.eshopData.eshop : 'neuvedeno',
                handler: 'Michal',
                status: 'Čeká na řešení'
            }
            try {
                const response = axios.post('/google-sheets/store', data);
            } catch (error) {
                console.error(error);
            }
        },

        getURLParams() {
            const params = new URLSearchParams(window.location.search);
            return {
                eshop: params.get('eshop'),
                tariff: params.get('tariff'),
                multilang: params.get('multilang') === 'true',
                isShoptet: params.get('is-shoptet') === 'true'
            };
        },

        handleProblemSelection(problem) {
            this.formData.problem = this.$t(problem.text);
            this.selectedProblem = problem.id;
            const problemId = problem.id;
            this.activeSteps = this.stepsMap[problemId] || [0];
            this.currentStep = this.activeSteps[0];
        },

        sendDataForCustomDesign() {
            const urlParams = new URLSearchParams(window.location.search);
            this.formData.email = urlParams.get('email');
            this.formData.problem = "Nezávazná poptávka úpravy na míru";
            this.sendEmail(false);
        },

        async makeStep(forward) {
            this.isTransitioning = true;

            setTimeout(() => {
                let currentIndex = this.activeSteps.indexOf(this.currentStep);
                if (forward && currentIndex < this.activeSteps.length - 1) {
                    this.currentStep = this.activeSteps[currentIndex + 1];
                } else if (!forward && currentIndex > 0) {
                    this.currentStep = this.activeSteps[currentIndex - 1];
                }
                this.isTransitioning = false;
            }, 300);
        },

        handleDataUpdate(key, value) {
            this.formData[key] = value;
        },

        msToTime(duration) {
            let seconds = Math.floor((duration / 1000) % 60);
            let minutes = Math.floor((duration / (1000 * 60)) % 60);
            let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;

            return hours + ":" + minutes + ":" + seconds;
        },


        sendEmail(save) {
            let loading = this;
            const {formData, selectedProblem, eshopData, $router, $route, sendDatToGoogleSheets} = this;
            this.loading = true;

            const endTime = Date.now();
            const timeSpent = endTime - this.startTime;
            this.formData.time = this.msToTime(timeSpent);

            try {
                grecaptcha.ready(function () {
                    try {
                        grecaptcha.execute('6Ldx1NcpAAAAAL2g_G065RScAnpTgi9OXt5hT37Y', {action: 'submit'}).then(function (token) {

                            let dataToSend = {
                                ...formData,
                                recaptchaToken: token,
                                /* files: this.uploadedFiles.map(file => ({
                                    path: file.path,
                                    name: file.name
                                })), */
                            };

                            if (selectedProblem !== 4) {
                                delete dataToSend.credits;
                            } else {
                                delete dataToSend.message;
                            }

                            if (selectedProblem !== 1 && selectedProblem !== 2) {
                                delete dataToSend.urls;
                            }

                            Object.assign(eshopData, dataToSend);

                            axios.post('/api/email/send', eshopData)
                                .then(response => {
                                    Cookies.set('poboSupportForm', JSON.stringify(eshopData), {expires: 1});
                                    loading = false;
                                    $router.push({name: 'SuccessPage', query: $route.query});
                                    if (save) {
                                        sendDatToGoogleSheets(response.data.request_id, response.data.date);
                                    }
                                })
                                .catch(error => {
                                    console.error('Chyba při odesílání emailu:', error);
                                    loading = false;
                                    $router.push({name: 'ErrorPage', query: $route.query});
                                });
                        }).catch(error => {
                            console.error('Chyba CAPTCHA:', error);
                            loading = false;
                            $router.push({name: 'ErrorPage', query: $route.query});
                        });
                    } catch (error) {
                        console.error('Chyba během vykonávání CAPTCHA:', error);
                        loading = false;
                        $router.push({name: 'ErrorPage', query: $route.query});
                    }
                });
            } catch (error) {
                console.error('Nastala systémová chyba:', error);
                loading = false;
                $router.push({name: 'ErrorPage', query: $route.query});
            }
        }

    }
}

</script>

<style scoped>
.design-selector-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.padding-disabled {
    padding: 0 0 20px 0 !important;
}

.version-text {
    font-size: 10px;
    color: gray;
}
</style>
