<template>
    <div class="pb-form-design-selector" :class="{'padding-disabled': orderDesign}">
        <div class="pb-form-design-selector__option-box">
            <label class="pb-form-design-selector__radio-option">
                <input
                    type="radio"
                    name="design"
                    :value="'Nová objednávka balíčku: ' + $t('Customizing the look')"
                    @change="emitMessage"
                    class="pb-form-design-selector__radio-input"
                    checked
                />
                <div class="pb-form-design-selector__radio-content">
                    <h3 class="pb-form-design-selector__radio-title">{{ $t('Customizing the look') }}</h3>
                    <p class="pb-form-design-selector__radio-description">
                        {{
                            $t('Ideal solution for smaller e-shops with a limited number of products that need to unify the look of the descriptions with the design of their website. The price is CZK 1,000/hour excluding VAT, and the modification usually takes 3-4 hours.')
                        }}
                    </p>
                </div>
            </label>
        </div>

        <div class="pb-form-design-selector__option-box">
            <label class="pb-form-design-selector__radio-option">
                <input
                    type="radio"
                    name="design"
                    :value="'Nová objednávka balíčku: ' + $t('Professional design with customized texts and online training')"
                    @change="emitMessage"
                    class="pb-form-design-selector__radio-input"
                />
                <div class="pb-form-design-selector__radio-content">
                    <h3 class="pb-form-design-selector__radio-title">
                        {{
                            $t('Professional design with customized texts and online training')
                        }}
                    </h3>
                    <p class="pb-form-design-selector__radio-description">
                        {{
                            $t('Ideal for medium and large e-shops with a wide range of products that want to differentiate themselves with professionally designed descriptions. The aim is to attract the customer at first sight and increase the chances of a sale. Price from 8 000 CZK.')
                        }}
                    </p>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "DesignSelector",

    data() {
        return {
            orderDesign: false
        }
    },

    methods: {
        emitMessage(event) {
            const selectedMessage = event.target.value;
            this.$emit("updateMessage", selectedMessage);
        },
    },

    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.orderDesign = urlParams.get('orderDesign') === 'true';
    },
};
</script>

<style scoped lang="scss">
.pb-form-design-selector {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    &__option-box {
        border: 2px solid #e0e0e0;
        border-radius: 10px;
        padding: 15px;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
            border-color: #649b0d;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        }
    }

    &__radio-option {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    &__radio-input {
        width: 40px;
        height: 40px;
        accent-color: #649b0d;
        cursor: pointer;
    }

    &__radio-content {
        flex-grow: 1;
    }

    &__radio-title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        color: #333;
    }

    &__radio-description {
        margin: 5px 0 0;
        font-size: 14px;
        color: #666;
    }
}
</style>
